@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fixed Header */
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add padding or margin to the main content */
main {
  margin-top: 5rem;
}




/* Navigation Dots styling */
.dot-container {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 10px;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
}

.dot.active {
  background-color: white;
}
