/* Updated global CSS file */

/* Header styles */
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the header stays above other content */
  background-color: white; /* Keep the background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow for visual separation */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for changes */
}

/* Header styling when scrolling */
header.scrolled {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent on scroll */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* More pronounced shadow when scrolled */
}

/* Add padding or margin to the main content */
main {
  margin-top: 5rem; /* Adjust this value based on your header's height */
  padding: 1rem;
  transition: margin-top 0.3s ease; /* Smooth transition for content margin */
}

/* Responsive design for mobile view */
@media (max-width: 768px) {
  header {
    padding: 1rem;
    box-shadow: none; /* Remove shadow for a cleaner mobile view */
  }

  main {
    margin-top: 4rem; /* Adjust the margin for smaller screens */
  }



  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    transform: translateX(0); /* Ensure the menu slides in smoothly */
  }
}
